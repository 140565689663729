import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, About, Install, Contact, Data, Explanation } from "components/landing";

const Home = () => (
    <Layout>
        <Seo />
        <Intro />
        <Explanation />
        <About />
        <Install />
        <Data />
        <Contact />
    </Layout>
);

export default Home;
